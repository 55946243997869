(function () {
    'use strict';

    angular.module('loginApp')
        .controller('LogoutController', LogoutController);

    LogoutController.$inject = ['SessionService', '$state', 'CommonService', 'rolesConstant', 'routesConstant',
    'Notification'];

    function LogoutController(SessionService, $state, CommonService, rolesConstant, routesConstant, Notification) {

        CommonService.logout().then(function() {
            var role = $state.params ? $state.params.role : "";
            switch(role) {
                case rolesConstant.ADMIN:
                    $state.go(routesConstant.LOGIN.ADMIN.stateName);
                    return;
                case rolesConstant.MFG:
                    $state.go(routesConstant.LOGIN.MFG.stateName);
                    return;
                case rolesConstant.CUSTOMER:
                default:
                    $state.go(routesConstant.LOGIN.CUSTOMER.stateName);
                    return;
            }
        })
        .catch(function() {
            Notification.error("There was an error logging you out");
        })
        .finally(function() {
            // always clear local info, even if the web had an error
            SessionService.removeSessionInfo();
        });
    }

})();
